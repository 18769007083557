import { Typography, Link as MuiLink } from '@mui/material';
import Link from 'next/link';
import { useEffect, useState } from 'react';
type TruncateTextProps = {
  text: string | null;
  postId?: string;
  textLength?: number;
};
export const TruncateText: React.FC<TruncateTextProps> = ({
  text,
  postId,
  textLength = 150
}) => {
  const [textToShow, setTextToShow] = useState('');
  const [showMore, setShowMore] = useState(false);
  const postDetailPath = postId ? `/content/${postId}` : '/';
  useEffect(() => {
    checkHasBlanks(text || '');
  }, [text]);
  const checkHasBlanks = (text: string) => {
    if (text.length > textLength) {
      setTextToShow(text.substring(0, textLength));
      setShowMore(true);
    } else {
      setTextToShow(text);
      setShowMore(false);
    }
  };
  return <Typography gutterBottom variant="body1" color="inherit" sx={{
    textDecoration: 'none',
    margin: 'auto',
    wordBreak: 'break-word',
    overflowWrap: 'break-word'
  }} data-sentry-element="Typography" data-sentry-component="TruncateText" data-sentry-source-file="TruncateText.tsx">
      <span dangerouslySetInnerHTML={{
      __html: textToShow
    }} />
      {showMore && <Link href={postDetailPath} passHref>
          <MuiLink underline="hover" component="span" sx={{
        marginLeft: '4px'
      }}>
            ... read more
          </MuiLink>
        </Link>}
    </Typography>;
};