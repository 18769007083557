// ** React & Mui
import InfiniteScroll from 'react-infinite-scroll-component';
import { useState, useEffect } from 'react';
import { Box, CircularProgress, Grid, Typography, useTheme } from '@mui/material';

// ** Components
import PageContainer from '../container/PageContainer';
import { FollowingItem } from './FollowingItem';
import { MintedItem } from './MintedItem';
import { useBackend } from '@/context/backend';

// ** Hooks
import { useLens } from '@/context/lens';
import { useSession } from '@/context/session';
import { CollectFeedItem, FeedItem, FollowFeedItem, SignInStatus } from '@/types/custom';
import { getSignInStatus, loadCredentials } from '@/utils/helpers';
import { useAccount } from 'wagmi';
import { CredentialType } from '@/types/auth/auth';
import { PostFragment } from '@lens-protocol/client';
export default function FeedComponent() {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState<FeedItem[]>([]);
  const [cursor, setCursor] = useState<string>();
  const [followStatus, setFollowStatus] = useState<Map<string, boolean>>(new Map());
  const [hasMore, setHasMore] = useState(true);
  const {
    getFeedPage
  } = useBackend();
  const {
    getPostsById,
    checkFollowStatusBulk,
    checkIsAuthenticated,
    isCheckingLocalKeys
  } = useLens();
  const {
    user
  } = useSession();
  const {
    isConnected,
    address
  } = useAccount();
  useEffect(() => {
    cleanFeed();
    fetchFeedItems();
    return cleanFeed;
  }, [user.profileId, isConnected]);
  const fetchFeedItems = async (cursorParam?: string) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const signInStatus = getSignInStatus();
      const isSignedIn = signInStatus === SignInStatus.completed;
      const isAuthenticated = await checkIsAuthenticated();
      const credentials = loadCredentials(address || '', CredentialType.lens);
      if (isConnected && credentials && isSignedIn && isAuthenticated && !user.profileId) return;
      if (isCheckingLocalKeys) return;
      const feedData = await getFeedPage(isConnected ? user.profileId : undefined, cursorParam);
      if (feedData && feedData.items && feedData.items.length > 0) {
        const processedItems = await processFeedItems(feedData.items);
        if (processedItems && processedItems.length > 0) {
          setPosts(prevPosts => {
            const uniqueItems = processedItems.filter(item => !prevPosts.some(p => p.id === item.id));
            return [...prevPosts, ...uniqueItems];
          });
        }
        setCursor(feedData.nextCursor);
        setHasMore(!!feedData.nextCursor);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching feed items:', error);
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  };
  const processFeedItems = async (items: FeedItem[]) => {
    const collectItems: CollectFeedItem[] = [];
    const followItems: FollowFeedItem[] = [];
    const uniquePublicationIds = new Set<string>();
    const uniqueProfileIds = new Set<string>();
    items.forEach(item => {
      if (item.type === 'collect' && 'publicationId' in item.data) {
        collectItems.push(item);
        uniquePublicationIds.add(item.data.publicationId);
      } else if (item.type === 'follow') {
        followItems.push(item);
        uniqueProfileIds.add(item.profiles.followedProfile.id);
      }
    });
    const followStatus = await checkFollowStatusBulk(Array.from(uniqueProfileIds));
    setFollowStatus(new Map(followStatus?.map(status => [status.profileId, status.status.value])));
    const uniquePublicationIdsArray = Array.from(uniquePublicationIds);
    if (uniquePublicationIdsArray.length) {
      const publicationsData = await getPostsById(uniquePublicationIdsArray);
      const publicationsMap = new Map(publicationsData.items.map((pub: any) => [pub.id, pub]));
      return items.map(item => {
        if (item.type === 'collect' && 'publicationId' in item.data) {
          return {
            ...item,
            data: {
              ...item.data,
              publication: publicationsMap.get(item.data.publicationId) || null
            }
          };
        }
        return item;
      });
    }
    return items;
  };
  const handleLoadMore = () => {
    if (hasMore) {
      fetchFeedItems(cursor);
    }
  };
  const cleanFeed = () => {
    setPosts([]);
    setCursor(undefined);
    setHasMore(true);
  };
  return <PageContainer data-sentry-element="PageContainer" data-sentry-component="FeedComponent" data-sentry-source-file="Feed.tsx">
      <Box data-sentry-element="Box" data-sentry-source-file="Feed.tsx">
        <InfiniteScroll dataLength={posts.length} next={handleLoadMore} hasMore={hasMore} loader={<Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '10vh'
      }}>
              <CircularProgress />
            </Box>} endMessage={<Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '10vh'
      }}>
              <Typography>No more posts to load</Typography>
            </Box>} scrollThreshold="10px" style={{
        overflow: 'visible'
      }} data-sentry-element="InfiniteScroll" data-sentry-source-file="Feed.tsx">
          <Grid container justifyContent="center" gap={3} data-sentry-element="Grid" data-sentry-source-file="Feed.tsx">
            {posts.map((post, i) => <Grid item my="0.2em" mx="0.2em" xs={12} sm={8} xl={6} key={i} sx={{
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;'
          }}>
                {post.type === 'follow' ? <FollowingItem item={post} initialFollowStatus={followStatus.get(post.profiles.followedProfile.id) || false} /> : <MintedItem item={post as CollectFeedItem & {
              data: {
                publication: PostFragment | null;
              };
            }} />}
              </Grid>)}
          </Grid>
        </InfiniteScroll>
      </Box>
    </PageContainer>;
}